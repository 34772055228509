import React from "react";

const RequestPage = () => {
  return (
    <div
      style={{
        minHeight: "280px",
        display: "flex",
        justifyContent: "center",
        margin: "1.4rem 0rem",
        alignItems: "center",
        // backgroundColor: "red"

      }}
    >
      <div
        style={{
          backgroundColor: "#E1E0E2",
          width: "60%",
          borderRadius: "8px",
          height: "100%!important",
          padding: "1rem"
        }}
      >
        <div
          style={{
          }}
        >
          <h3 >Dear Customer</h3>
        </div>
        <div
          style={{
            padding: "0rem 1.3rem",
            textAlign: "center",
          }}
        >
          <strong>
            <span style={{ color: "rgb(35, 145, 254)", fontSize: "1.1rem" }}>
              {" "}
              Maqbuul™{" "}
            </span>
            is committed to protecting the privacy and security of our users. We
            want to assure you that we do not collect any data from our users
            for any purposes other than to confirm the user is real, verify
            order and delivery location during the order phase. In the event
            that you have canceled your order and would like to delete it from
            our database history, please submit your request to our Email:
            <p style={{ color: "rgb(35, 145, 254)", overflow: "hidden" }}>
              Maqbl.online@gmail.com
            </p>
            We appreciate your trust in our platform and will continue to uphold
            our commitment to privacy.
          </strong>
        </div>
      </div>
    </div>
  );
};

export default RequestPage;
