import React from "react";

import {
  MdCloudDownload,
  MdBookmarkAdded,
  MdDeliveryDining,
  MdOutlineArrowForwardIos,
} from "react-icons/md";

import { Row, Col } from "react-bootstrap";

import OrderClasses from "./Order.module.scss";

function Order() {
  return (
    <div className={OrderClasses.orderContainer}>
      <h2 className={OrderClasses.headerText}>Follow these Steps</h2>
      <Row>
        <Col>
          <div className={OrderClasses.orderDiv}>
            <MdCloudDownload size={28} />
            <h3>Download</h3>
            <MdOutlineArrowForwardIos size={28} />
          </div>
          <p className={OrderClasses.text}>After Downloading create an account and login.</p>
        </Col>
        <Col>
          <div className={OrderClasses.orderDiv}>
            <MdBookmarkAdded size={28} />
            <h3>Order</h3>
            <MdOutlineArrowForwardIos size={28} />
          </div>
          <p className={OrderClasses.text}>Make an order of your liking from our various products.</p>
        </Col>
        <Col>
          <div className={OrderClasses.orderDiv}>
            <MdDeliveryDining size={28} />
            <h3>We Deliver!</h3>
            <MdDeliveryDining size={28} />
          </div>
          <p className={OrderClasses.text}>
            We will contact you and make sure your order is delivered as soon as
            possible.
          </p>
        </Col>
      </Row>
    </div>
  );
}

export default Order;
