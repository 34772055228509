import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function Info({ message }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Contact us
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Contact Info</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            textAlign: "center",
          }}
        >
          <p>
            <span
            style={{
              fontWeight: '600',
              fontSize: '1.2rem',
              color: "rgb(35, 145, 254)"
            }}
            >Maqbuul™</span>  is your reliable eCommerce partner in East Africa for
            shopping Mobiles, Tablets, Computers, Electronics, Accessories and
            More.
          </p>
          <p>For further informations you can contact us at</p>
          <span>E-Mail: </span>
          <p
            style={{
              display: "inline",
              fontWeight: "600",
              color: "rgb(35, 145, 254)",
              textDecoration: "underline",
              fontSize: "20px",
            }}
          >
            Maqbl.online@gmail.com
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div></div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Info;
