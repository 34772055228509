import React from "react";

import HeroClasses from "./Hero.module.scss";
import { Row, Col } from "react-bootstrap";

import heroImage from "../../assets/maqbuul_display-c.webp";
import heroImage2 from "../../assets/hero_mi_13.webp";
import googlePlay from "../../assets/google-play-badge.png";
import appStore from "../../assets/app_store.webp";

function Hero() {
  return (
    <Row>
      <Col md={6} className={HeroClasses.heroTextCol}>
        <div className={HeroClasses.heroImgDiv}>
          <img src={heroImage2} />
        </div>
      </Col>
      <Col className={HeroClasses.heroTextCol}>
        <div className={HeroClasses.heroTextDiv}>
          <h3>Get our App on PlayStore and Shop with Quality!</h3>
          <p>
            Maqbuul is your reliable eCommerce partner in East Africa for
            shopping Mobiles, Tablets, Computers, Electronics, Accessories and
            More.
          </p>
          <div>
            <a href="https://play.google.com/store/apps/details?id=com.maqbuul.maqbuul&hl=en_IN&gl=US">
              <img src={googlePlay} alt="" />
            </a>
            <a href="#"
            >
              <img src={appStore} alt="" style={{ height: "52px", marginLeft: '0.8rem'}} />
            </a>
            <small>*Comming Soon!</small>
          </div>
          <div></div>
        </div>
      </Col>
    </Row>
  );
}

export default Hero;
