import React from "react";

import Maqbuul_logo from "../../assets/Maqbuul_logo-c.webp";

import NavbarClasses from "./Navbar.module.scss";

import Info from "../Info/Info.comp";

function Navbar() {
  return (
    <div className={NavbarClasses.navbar}>
      <div className={NavbarClasses.navbarHeader}>
        <img src={Maqbuul_logo}/>
        <a href="/">MaqbuulOnline.com</a>
      </div>
      <div className={NavbarClasses.buttonDiv}>
        <Info
          message=" Maqbuul is your reliable eCommerce partner in East Africa for
            shopping Mobiles, Tablets, Computers, Electronics, Accessories and More.
            "
        />
      </div>
    </div>
  );
}

export default Navbar;
