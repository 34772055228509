import React from 'react'
import Container from "react-bootstrap/Container";
import Navbar from "../components/Navbar/Navbar.comp";
import Hero from "../components/Hero/Hero.comp";
import Order from "../components/Ordering/Order.comp";
import Footer from "../components/Footer/Footer.comp";



function HomePage() {
  return (
    <Container className={`newContainer`}>
    <Hero />
    <Order />
  </Container>
  );
}

export default HomePage;