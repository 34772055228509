import "./App.css";

import Container from "react-bootstrap/Container";
import Navbar from "./components/Navbar/Navbar.comp";
import Hero from "./components/Hero/Hero.comp";
import Order from "./components/Ordering/Order.comp";
import Footer from "./components/Footer/Footer.comp";
import React from "react";

import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
} from "react-router-dom/cjs/react-router-dom.min";
import HomePage from "./pages/HomePage";
import RequestPage from "./pages/RequestPage";

function App() {
  return (
    <Router>
      <React.Fragment>
        <Switch>
          <div
            style={{
              height: "100vh!important",
            }}
          >
            <Navbar />
            <Switch>
              <Route path="/" exact>
                <HomePage />
              </Route>
              <Route path="/remove_user_data">
                <RequestPage />
              </Route>
            </Switch>
          </div>
        </Switch>
        <Footer />
      </React.Fragment>
    </Router>
  );
}

export default App;

{
  /* <Container className={`newContainer`}>
          <Hero />
          <Order />
        </Container> */
}
